import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { IntlModule, tr } from '@rcg/intl';
import { RcgDialogComponent } from '@rcg/standalone/components';
import { FilterFormResult, FilterFormSetting, ResetFiltersResult } from '../../models/filters-settings';
import { FiltersFormComponent } from '../filters-form/filters-form.component';

@Component({
  selector: 'rcg-filters-dialog',
  standalone: true,
  imports: [CommonModule, FiltersFormComponent, MatDialogModule, RcgDialogComponent, IntlModule],
  templateUrl: './filters-dialog.component.html',
  styleUrls: ['./filters-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogSetting: FilterFormSetting,
    public dialogRef: MatDialogRef<FiltersDialogComponent, FilterFormSetting>,
  ) {}

  filterFormSetting?: FilterFormSetting;
  errorMessage = toSignal(tr('wrong_filters_dialog_settings'));
  categoryTranslation = signal<string | undefined>(undefined);

  ngOnInit(): void {
    if (this.dialogSetting && Object.keys(this.dialogSetting).length > 0) {
      this.filterFormSetting = {
        ...this.dialogSetting,
      };
      if (this.filterFormSetting?.favoritesSettings?.category_translation) {
        this.categoryTranslation.set(this.filterFormSetting.favoritesSettings.category_translation);
      }
    }
  }

  onFiltersSubmit(data: FilterFormResult) {
    if (this.dialogSetting?.onSubmitAction) {
      this.dialogSetting.onSubmitAction(data);
    }
    this.dialogRef.close();
  }

  onFiltersReset(data: ResetFiltersResult) {
    if (this.dialogSetting?.onResetAction) {
      this.dialogSetting.onResetAction(data);
    }
    this.dialogRef.close();
  }
}
