<mat-menu #menu="matMenu">
  <ng-container *ngIf="actionsError$ | async as error">
    <div class="error">{{ 'action_error' | intl }}: {{ error }}</div>
  </ng-container>
  <ng-container *ngIf="actions$ | async as actions">
    <button mat-menu-item *ngFor="let action of actions" (click)="executeAction($event, action)">
      <mat-icon>{{ action.icon }}</mat-icon>
      <span>{{ action.description }}</span>
    </button>
  </ng-container>
</mat-menu>

<div style="display: flex; flex-direction: row; justify-content: end">
  <button
    (click)="getActions($event)"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    matTooltipPosition="below"
    [matTooltip]="'actions' | intl"
  >
    <mat-icon color="primary">more_vertical</mat-icon>
  </button>
</div>
