import { Pipe, PipeTransform } from '@angular/core';
import { IAction, ToggleDataButtonActionConfig } from '../models/actions';


@Pipe({
    name: 'actionColor',
    pure: true,
    standalone: true
})
export class ActionColorPipe implements PipeTransform {

    transform(action: IAction): string {
        if (action.method === 'toggle-data-button') {
            const config = action.config as ToggleDataButtonActionConfig;
            if (!config) return '';
            return config.active ? 'accent' : 'primary';
        }
        return 'primary';
    }
}

@Pipe({
    name: 'actionTooltip',
    pure: true,
    standalone: true
})
export class ActionTooltipPipe implements PipeTransform {

    transform(action: IAction): string {
        if (action.method === 'toggle-data-button') {
            const config = action.config as ToggleDataButtonActionConfig;
            if (!config) return '';
            return config.active ? config.activeTooltip ?? '' : config.notActiveTooltip ?? '';
        }
        return action.tooltip ?? '';
    }
}

@Pipe({
    name: 'actionIcon',
    pure: true,
    standalone: true
})
export class ActionIconPipe implements PipeTransform {

    transform(action: IAction): string {
        if (action.method === 'toggle-data-button') {
            const config = action.config as ToggleDataButtonActionConfig;
            if (!config) return '';
            return config.active ? config.activeIcon ?? '' : action.icon ?? '';
        }
        return action.icon ?? '';
    }
}