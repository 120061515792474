import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactCardData } from '@rcg/core/models';
import { ContactEditDialogComponent, ContactEditDialogData } from '@rcg/forms';

@Component({
  selector: 'rcg-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
})
export class ContactListComponent {
  @Input() data?: ContactCardData[];

  @Input() title?: string;

  @Input() cardZIndex?: number;

  @Input() allowEdit = true;

  constructor(public dialog: MatDialog) {}

  openEditDialog(data: ContactCardData) {
    if (!this.allowEdit) return;

    if (!data?.id) {
      console.error('No contact id, not opening edit dialog:', data);
      return;
    }

    this.dialog.open(ContactEditDialogComponent, {
      minWidth: '300px',
      width: '600px',
      maxWidth: '80vw',
      data: {
        id: data.id,
      } as ContactEditDialogData,
    });
  }
}
