import { FormlyFieldConfig } from '@ngx-formly/core';
import { WhereExpression } from '@npm-libs/ng-templater';
import { SqlWhereExpression } from '@rcg/filters/models';
import { tr } from '@rcg/intl';
import { combineLatest, firstValueFrom, map } from 'rxjs';
import { WhereFilterConfig } from '../../models/filter-configs';
import { OperatorSelectOption } from '../../models/filter-operators';
import { IWhereFilter } from '../base-filters';

export type NumberFilterOperator = 'eq' | 'lt' | 'lte' | 'gt' | 'gte';

export interface NumberFilterConfig extends WhereFilterConfig {
  operator: OperatorSelectOption[];
  defaultOperator?: NumberFilterOperator;
}

export class NumberFilter extends IWhereFilter<NumberFilterConfig> {
  private get opratorFiledKey() {
    return `${this.fieldKey}_number_operator`;
  }

  private get defaultOperator(): NumberFilterOperator {
    return this.config.defaultOperator ?? 'eq';
  }

  labelTr$ = combineLatest([tr('condition_for'), tr(this.config.title)]).pipe(map(([condition, title]) => `${condition} ${title}`));

  override createFields(): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: 'd-lg-flex align-items-lg-stretch',
        fieldGroup: [
          {
            className: 'col-lg-4  pe-lg-3',
            key: this.opratorFiledKey,
            type: 'select',
            defaultValue: this.defaultOperator,
            expressions: {
              'props.label': this.labelTr$,
            },
            props: {
              options: this.operatorsOptionsToSelectTr$(this.config.operator),
            },
          },
          {
            className: 'col-lg-8',
            key: this.fieldKey,
            type: 'input',
            expressions: {
              'props.label': tr(this.config.title ?? ''),
              'props.placeholder': tr(this.config.placeholder ?? ''),
            },
            props: {
              type: 'number',
              label: this.config.title,
              placeholder: this.config.placeholder ?? '',
            },
          },
        ],
      },
    ];
  }

  override createGqlFilterExpression(data: Record<string, unknown>): WhereExpression[] {
    const whereExpressions: WhereExpression[] = [];

    const value = this.getValue<number | null>(data, this.fieldKey);
    const operator = (data[this.opratorFiledKey] as NumberFilterOperator) ?? this.defaultOperator;

    if (value) {
      switch (operator) {
        case 'eq':
        case 'lt':
        case 'lte':
        case 'gt':
        case 'gte':
          whereExpressions.push({
            operator: operator,
            field: this.config.field,
            value: value,
          });
          break;
        default:
          console.error(`Number filter - unsupported operator: ${operator}`);
          break;
      }
    }
    return whereExpressions;
  }

  override createSqlFilterExpression(data: Record<string, unknown>): SqlWhereExpression[] {
    const value = this.getValue<number | null>(data, this.fieldKey);
    return value
      ? [
          {
            field: this.config.field,
            operator: (data[this.opratorFiledKey] as NumberFilterOperator) ?? this.defaultOperator,
            value: value,
          },
        ]
      : [];
  }

  override async getFilterDescription(data: Record<string, unknown>): Promise<string> {
    const value = data[this.fieldKey] as number | undefined;
    if (!value) return '';
    const operator = this.getValue<NumberFilterOperator | null>(data, this.opratorFiledKey);
    const title = await firstValueFrom(tr(this.config.title));
    return `${title} ${this.getOperatorTitle(operator)} ${value}`;
  }

  private getOperatorTitle(operator?: NumberFilterOperator | null): string {
    if (!operator) return '';
    switch (operator) {
      case 'eq':
        return '=';
      case 'gt':
        return '>';
      case 'gte':
        return '>=';
      case 'lt':
        return '<';
      case 'lte':
        return '<=';
      default:
        return '';
    }
  }
}
