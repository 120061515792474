import { FormlyFieldConfig } from '@ngx-formly/core';
import { WhereExpression } from '@npm-libs/ng-templater';
import { SqlWhereExpression } from '@rcg/filters/models';
import { tr } from '@rcg/intl';
import { combineLatest, firstValueFrom, map } from 'rxjs';
import { WhereFilterConfig } from '../../../models/filter-configs';
import { OperatorSelectOption } from '../../../models/filter-operators';
import { IWhereFilter } from '../../base-filters';
import { DateFilterExpression } from './expression-factory';

export type DateFilterOperator =
  | 'eq'
  | 'day'
  | 'yesterday'
  | 'week'
  | 'month'
  | 'year'
  | 'previousWeek'
  | 'previousMonth'
  | 'gt'
  | 'gte'
  | 'lt'
  | 'lte'
  | 'between';

export interface DateFilterConfig extends WhereFilterConfig {
  operator: OperatorSelectOption[];
  defaultOperator: DateFilterOperator;
}

export class DateFilter extends IWhereFilter<DateFilterConfig> {
  private get opratorFiledKey() {
    return `${this.fieldKey}_date_operator`;
  }

  private get toDateFieldName() {
    return `${this.fieldKey}_to`;
  }

  private get defaultOperator(): DateFilterOperator {
    if (!this.config.defaultOperator || !this._config.operator?.length) return 'eq';
    return this.config.defaultOperator ?? this._config.operator[0].value;
  }

  private isFilterPeriodOperatorActive(data: Record<string, unknown>): boolean {
    const operatorValue = data[this.opratorFiledKey] as DateFilterOperator | undefined;
    if (!operatorValue) return false;
    return (['day', 'yesterday', 'week', 'month', 'year', 'previousWeek', 'previousMonth'] as DateFilterOperator[]).includes(operatorValue);
  }

  private getOperator(data: Record<string, unknown>): DateFilterOperator {
    const hasOperatorValue = data[this.opratorFiledKey] && Object.keys(data[this.opratorFiledKey] as string).length > 0;
    return hasOperatorValue ? (data[this.opratorFiledKey] as DateFilterOperator) : this.defaultOperator;
  }

  conditionLabelTr$ = combineLatest([tr('condition_for'), tr(this.config.title)]).pipe(
    map(([condition, title]) => `${condition} ${title}`),
  );

  dateToTitle$ = combineLatest([tr(this.config.title), tr('to')]).pipe(map(([title, to]) => `${title} ${to} `));

  override createFields(): FormlyFieldConfig[] {
    const operatorFieldKey = this.opratorFiledKey;

    return [
      {
        fieldGroupClassName: 'd-lg-flex align-items-lg-stretch',
        fieldGroup: [
          {
            className: 'col-lg-4  pe-lg-3',
            key: this.opratorFiledKey,
            type: 'select',
            defaultValue: this.defaultOperator,
            expressions: {
              'props.label': this.conditionLabelTr$,
            },
            props: {
              options: this.operatorsOptionsToSelectTr$(this.config.operator),
            },
          },
          {
            className: 'col-lg-4 pe-lg-3',
            key: this.config.field,
            type: 'date-picker',
            expressions: {
              className({ model }) {
                return model[operatorFieldKey] === 'between' ? 'col-lg-4  pe-lg-3' : 'col-lg-8';
              },
              'props.label': tr(this.config.title),
            },
            expressionProperties: {
              'props.label': tr(this.config.title),
              'props.disabled': (model) => this.isFilterPeriodOperatorActive(model),
            },
          },
          {
            className: 'col-lg-4',
            key: this.toDateFieldName,
            type: 'date-picker',
            expressions: {
              'props.label': this.dateToTitle$,
            },
            hideExpression: (model) => model[operatorFieldKey] !== 'between',
          },
        ],
      },
    ];
  }

  override createGqlFilterExpression(data: Record<string, unknown>): WhereExpression[] {
    const date = this.getDate(this.fieldKey, data);
    const toDate = this.getDate(this.toDateFieldName, data);

    const expression = DateFilterExpression.createGqlExpression(this.config.field, this.getOperator(data), date, toDate);
    return expression ? [expression] : [];
  }

  override createSqlFilterExpression(data: Record<string, unknown>): SqlWhereExpression[] {
    const date = this.getDate(this.fieldKey, data);
    const toDate = this.getDate(this.toDateFieldName, data);
    const expression = DateFilterExpression.createSqlExpression(this.config.field, this.getOperator(data), date, toDate);
    return expression ? [expression] : [];
  }

  override async getFilterDescription(data: Record<string, unknown>): Promise<string> {
    const selectedOperator = data[this.opratorFiledKey] as DateFilterOperator | undefined | null;
    if (!selectedOperator) return '';

    const titleValue = this.config.operator.find((s) => s.value === selectedOperator)?.label ?? '';

    const title = await firstValueFrom(tr(this.config.title));

    if (this.isFilterPeriodOperatorActive(data)) {
      return `${title}: ${titleValue}`;
    }

    const fromDate = this.getDate(this.fieldKey, data);
    if (!fromDate) return '';

    const toDate = this.getDate(this.toDateFieldName, data);

    return `${title}: ${titleValue} : ${fromDate.toLocaleDateString()} ${toDate ? ` - ${toDate.toLocaleDateString()}` : ''}`;
  }

  private getDate(fieldKey: string, data: Record<string, unknown>): Date | undefined {
    const date = data[fieldKey] as Date | string | undefined;
    if (!date) return undefined;
    if (typeof date === 'string') {
      return new Date(date);
    }
    return date;
  }
}
