import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '@rcg/auth';
import { IntlModule } from '@rcg/intl';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { IAction } from '../../models/actions';
import { ActionsService } from '../../services/actions.service';

@Component({
  selector: 'rcg-menu-actions',
  standalone: true,
  templateUrl: './menu-actions.component.html',
  styleUrls: ['./menu-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatMenuModule, MatButtonModule, MatIconModule, MatTooltipModule, MatProgressBarModule, IntlModule],
})
export class MenuActionsComponent {
  @Input() formId?: number;

  @Input() recordId?: number;

  @Input() moduleId?: number;

  actionsError$ = new BehaviorSubject<string | null>(null);

  loading$ = new BehaviorSubject<boolean>(false);

  actions$ = new BehaviorSubject<IAction[]>([]);

  constructor(private actionsService: ActionsService, private authService: AuthService) {}

  async getActions(event: MouseEvent): Promise<void> {
    try {
      event?.stopPropagation();
      event?.preventDefault();

      this.loading$.next(true);
      this.actionsError$.next(null);

      if (!this.formId || !this.recordId || !this.moduleId) {
        const error = !this.formId ? 'FormId' : !this.recordId ? 'RecordId' : 'ModuleId';
        this.actionsError$.next(`Missing required actions input for recordId ${this.recordId}. Missing input: ${error}`);
        return;
      }

      const tenant = this.authService.tenant()!;

      const actions = await firstValueFrom(
        this.actionsService.getFormActions({
          contextType: 'list',
          formId: this.formId!,
          recordId: this.recordId!,
          moduleId: this.moduleId!,
          organizationId: tenant.organization.id,
          tenantId: tenant.id,
        }),
      );
      this.actions$.next(actions);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Menu actions error', error);
      this.actions$.next([]);
      this.actionsError$.next(error?.message ?? error?.toString());
    } finally {
      this.loading$.next(false);
    }
  }

  async executeAction(_: MouseEvent, action: IAction): Promise<void> {
    await this.actionsService.executeAction(action);
  }
}
