import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IntlModule } from '@rcg/intl';
import { IAction } from '../../../models/actions';
import { speedDialFabAnimations } from './animations';

type FabTogglerState = 'active' | 'inactive';

@Component({
  selector: 'rcg-multi-fab-actions',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatTooltipModule, IntlModule],
  templateUrl: './multi-fab-actions.component.html',
  styleUrls: ['./multi-fab-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: speedDialFabAnimations,
})
export class MultiFabActionsComponent implements OnChanges {
  @Input() actions: IAction[] = [];

  @Input() expandableButtonIcon = 'add';

  @Input() alwaysExpanded = false;

  @Input() collapseOnClick = true;

  @Output() actionClicked = new EventEmitter<IAction>();

  actionButtons: IAction[] = [];

  fabTogglerState: FabTogglerState = 'inactive';

  ngOnChanges(changes: SimpleChanges): void {
    if (this.alwaysExpanded && changes?.['actions']) {
      const prevActions = changes['actions']?.previousValue ?? [];
      const currentActions = changes['actions']?.currentValue ?? [];
      if (this.areSameActions(prevActions, currentActions)) return;
      this.actionButtons = currentActions;
    }
  }

  onFabToggle() {
    if (this.alwaysExpanded) return;
    this.actionButtons.length ? this.hideActions() : this.showActions();
  }

  onActionClick(action: IAction) {
    this.actionClicked.emit(action);
    if (!this.alwaysExpanded && this.collapseOnClick) {
      this.hideActions();
    }
  }

  private showActions() {
    this.fabTogglerState = 'active';
    this.actionButtons = this.actions;
  }

  private hideActions() {
    this.fabTogglerState = 'inactive';
    this.actionButtons = [];
  }

  private areSameActions(actions1: IAction[], actions2: IAction[]): boolean {
    if (actions1.length !== actions2.length) return false;

    for (const a1 of actions1) {
      const a2 = actions2.find((a2) => a2.name === a1.name);
      if (!a2?.name) return false;
    }
    return true;
  }
}
