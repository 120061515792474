import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  signal,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { AuthService, RcgUser } from '@rcg/auth';
import { FilterExpressions } from '@rcg/core/models/filter-expressions';
import { IFilter } from '@rcg/filters/filters/base-filters';
import {
  FavoriteAction,
  FavoriteResult,
  FavoritesGroup,
  FavoritesUser,
  FilterDataType,
  FilterFavoriteSettings,
  FiltersButtonsConfig,
} from '@rcg/filters/models';
import { OrderByFilter } from '@rcg/filters/order-by/order-by';
import { FiltersBuilderService } from '@rcg/filters/services';
import { RcgFormsModule } from '@rcg/forms';
import { IntlModule } from '@rcg/intl';
import { Subscription, debounceTime } from 'rxjs';
import { FilterFormResult, FilterFormSetting, ResetFiltersResult } from '../../models/filters-settings';
import { SqlFilterExpressions } from '../../models/sql-filter-expressions';

export type AfterResetFormAction = 'dirty' | 'pristine';
@Component({
  selector: 'rcg-filters-form',
  standalone: true,
  templateUrl: './filters-form.component.html',
  styleUrls: ['./filters-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
  ],
  imports: [CommonModule, RcgFormsModule, MatButtonModule, IntlModule],
})
export class FiltersFormComponent implements OnChanges, OnDestroy {
  @HostBinding('class.bootstrap-styled') private bootstrapStyled = true; //! Required for styling to work

  @Input() filterFormSetting!: FilterFormSetting;

  @Input() focusFirstFieldOnInit?: boolean;

  @Input() buttons?: FiltersButtonsConfig;

  @Input() filterType?: FilterDataType = 'gql';

  @Input() allowSubmitEmptyForm?: boolean = false;

  @Input() afterResetFormAction?: AfterResetFormAction = 'dirty';

  @Output() resetFilter = new EventEmitter<ResetFiltersResult>();

  @Output() submitFilter = new EventEmitter<FilterFormResult>();

  @Output() submitSqlFilter = new EventEmitter<SqlFilterExpressions>();

  @Output() sqlFilterChanges = new EventEmitter<SqlFilterExpressions>();

  @Output() gqlFilterChanges = new EventEmitter<FilterExpressions>();

  @ViewChild('formComponent', { read: ElementRef })
  formComponent?: ElementRef<HTMLElement>;

  constructor(private changeRef: ChangeDetectorRef, private filtersBuilderService: FiltersBuilderService, private auth: AuthService) {}

  private formValueChangeSubscription?: Subscription;

  private filters: IFilter[] = [];
  private orderBy?: OrderByFilter;

  readonly user = this.auth.user;

  loading = signal<boolean>(false);
  error = signal<string | null>(null);

  model: Record<string, unknown> | null = null;
  fields: FormlyFieldConfig[] = [];
  form: UntypedFormGroup | null = null;
  formOptions: FormlyFormOptions | null = null;
  title: string | null = null;

  async ngOnChanges(changes: SimpleChanges) {
    if (
      changes['filterFormSetting'].currentValue &&
      changes['filterFormSetting'].previousValue !== changes['filterFormSetting'].currentValue
    ) {
      this.createFiltersForm(changes['filterFormSetting'].currentValue);
    }
  }

  ngOnDestroy(): void {
    this.formValueChangeSubscription?.unsubscribe();
  }

  async submitForm(event: MouseEvent) {
    try {
      event.stopPropagation();
      event.preventDefault();

      if (!this.model || !this.form || this.form.invalid || (!this.allowSubmitEmptyForm && this.form.pristine)) {
        return;
      }

      if (this.filterType === 'sql') {
        const filterSqlExpressions = await this.filtersBuilderService.createSqlFilterExpressions(this.filters, this.model);
        this.submitSqlFilter.emit(filterSqlExpressions);
        return;
      }

      const filterGqlExpressions = await this.filtersBuilderService.createGqlFilterExpressions(this.filters, this.model);
      const orderByExprerssions = this.filtersBuilderService.createOrderByExpression(this.orderBy, this.model);

      this.submitFilter.emit({
        filters: {
          whereExpressions: filterGqlExpressions.whereExpressions,
          orderByExpressions: orderByExprerssions.orderByExpressions,
          filtersDescription: filterGqlExpressions.title,
          orderbyDescription: orderByExprerssions.tilte,
        },
        filterFormSetting: { ...this.filterFormSetting, formModel: this.model },
        favoriteResult: this.getFavoritesResult(this.filterFormSetting?.favoritesSettings, this.model, this.user()!),
      });

      this.form.markAsPristine();
    } catch (error) {
      console.error('Submit filter form error:', (error as Error)?.message ?? error);
    }
  }

  resetForm(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.model = { ...{} };
    if (this.afterResetFormAction === 'dirty') {
      this.form?.markAsDirty();
    }
    if (this.afterResetFormAction === 'pristine') {
      this.form?.markAsPristine();
    }

    const favoriteId = this.filterFormSetting?.favoritesSettings?.favorite?.id;
    this.resetFilter.emit({
      id: favoriteId ? favoriteId : this.filterFormSetting.id,
      favorite: this.filterFormSetting?.favoritesSettings?.favorite,
    });
  }

  private async createFiltersForm(settings: FilterFormSetting) {
    try {
      if (!settings?.filters || settings.filters.length === 0) throw new Error('No filters settings');

      this.loading.set(true);
      this.error.set(null);

      const filters = await this.filtersBuilderService.createFilters(settings.filters, settings.order_by);
      this.filters = filters.filters;
      this.orderBy = filters.orderBy;
      this.fields = await this.filtersBuilderService.createFormFields(this.filters, this.orderBy, settings?.favoritesSettings);

      this.model = settings.formModel ?? {};

      // clear save favorites values
      if (this.model['favorites_description']) {
        this.model['favorites_description'] = '';
      }
      if (this.model['favorites_save']) {
        this.model['favorites_save'] = false;
      }

      // clear users and groups values id when is not favorite
      if (!this.filterFormSetting?.favoritesSettings?.favorite?.id) {
        if (this.model['favorite_groups']) {
          this.model['favorite_groups'] = [];
        }

        if (this.model['favorite_users']) {
          this.model['favorite_users'] = [];
        }
      }

      this.formOptions = {};
      this.form = new UntypedFormGroup({});

      this.loading.set(false);
      this.changeRef.markForCheck();

      if (this.sqlFilterChanges.observed || this.gqlFilterChanges) {
        // emit realtime filter changes
        try {
          this.formValueChangeSubscription?.unsubscribe();
          this.formValueChangeSubscription = this.form.valueChanges.pipe(debounceTime(300)).subscribe(async () => {
            if (!this.model || !this.form || this.form.invalid) {
              return;
            }
            if (this.filterType === 'sql') {
              const filterSqlExpressions = await this.filtersBuilderService.createSqlFilterExpressions(this.filters, this.model);
              this.sqlFilterChanges.emit(filterSqlExpressions);
              return;
            }

            const filterGqlExpressions = await this.filtersBuilderService.createGqlFilterExpressions(this.filters, this.model);
            const orderByExprerssions = this.filtersBuilderService.createOrderByExpression(this.orderBy, this.model);
            this.gqlFilterChanges.emit({
              orderByExpressions: orderByExprerssions.orderByExpressions,
              whereExpressions: filterGqlExpressions.whereExpressions,
            });
          });
        } catch (error) {
          console.error('Realtime filter changes error:', (error as Error)?.message ?? error);
        }
      }
    } catch (error) {
      console.error('Create filters form error:', (error as Error)?.message ?? error);
      this.error.set((error as Error)?.message ?? error?.toString() ?? 'Unknown error');
      this.loading.set(false);
    }
  }

  private getFavoritesResult(
    favoritesSettings: FilterFavoriteSettings | undefined,
    model: Record<string, unknown>,
    user: RcgUser,
  ): FavoriteResult {
    const favorite = favoritesSettings?.favorite;
    const favoriteId = favorite?.id;
    const favoriteOwner = user.id === favorite?.user_id;

    const description = favoriteId
      ? (model?.['favorites_description_edit'] as string | undefined)
      : (model?.['favorites_description'] as string | undefined);

    const canInsertFavorite =
      !favoriteId &&
      !!description &&
      (model?.['favorites_save'] as boolean | undefined) === true &&
      favoritesSettings?.category &&
      favoritesSettings?.favoritesRoutePath &&
      favoritesSettings?.moduleId;

    const action: FavoriteAction = favoriteId && favoriteOwner ? 'update' : canInsertFavorite ? 'insert' : 'no-action';

    return {
      action: action,
      favoriteId: favoriteId,
      description: description,
      category: favoritesSettings?.category,
      routePath: favoritesSettings?.favoritesRoutePath,
      moduleId: favoritesSettings?.moduleId,
      users: ((model?.['favorite_users'] as FavoritesUser[]) ?? []).map((u) => u.id),
      groups: ((model?.['favorite_groups'] as FavoritesGroup[]) ?? []).map((g) => g.id),
    };
  }
}
