import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IntlModule } from '@rcg/intl';
import { IAction } from '../../../models/actions';

@Component({
  selector: 'rcg-single-fab-action',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatTooltipModule, IntlModule],
  templateUrl: './single-fab-action.component.html',
  styleUrls: ['./single-fab-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleFabActionComponent {
  @Input() actions: IAction[] = [];

  @Output() actionClicked = new EventEmitter<IAction>();

  onActionClick(action: IAction) {
    this.actionClicked.emit(action);
  }
}
