<ng-container *ngIf="actions && actions.length === 1">
  <div class="fab-action">
    <button
      mat-fab
      color="primary"
      (click)="onActionClick(actions[0])"
      [matTooltip]="(actions[0].tooltip | intl) ?? ''"
      matTooltipPosition="left"
    >
      <mat-icon>{{ actions[0].icon ?? '' }}</mat-icon>
    </button>
  </div>
</ng-container>
