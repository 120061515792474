import { WhereExpression } from '@npm-libs/ng-templater';
import { SqlWhereExpression } from '@rcg/filters/models';
import moment from 'moment';
import { DateFilterOperator } from '../date-filter';
import { DateExpression } from './base';

export class MonthExpression extends DateExpression {
  constructor(field: string, operator: DateFilterOperator, date: Date | undefined) {
    super(field, operator, date);
  }

  override createGqlExpression(): WhereExpression {
    return {
      condition: 'and',
      predicates: [
        {
          operator: 'gte',
          field: this.field,
          value: moment().startOf('month').toISOString(),
        },
        {
          operator: 'lte',
          field: this.field,
          value: moment().endOf('month').toISOString(),
        },
      ],
    };
  }

  override createSqlExpression(): SqlWhereExpression | undefined {
    return {
      field: this.field,
      operator: this.operator,
      value: moment().startOf('month').toISOString(),
    };
  }
}
