<mat-menu #menu="matMenu">
  <ng-container *ngIf="loading$ | async; else noLoading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-container>
  <ng-template #noLoading>
    <ng-container *ngIf="actions$ | async as actions">
      <ng-container *ngIf="actionsError$ | async as error; else actionsTmpl">
        <div class="error">{{ 'action_error' | intl }}: {{ error }}</div>
      </ng-container>
      <ng-template #actionsTmpl>
        <ng-container *ngIf="actions && actions.length > 0; else noActions">
          <button mat-menu-item *ngFor="let action of actions" (click)="executeAction($event, action)">
            <mat-icon>{{ action.icon }}</mat-icon>
            <span>{{ action.description | intl : { default: action.description ?? '' } }}</span>
          </button>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-template>
</mat-menu>

<div style="display: flex; flex-direction: row; justify-content: end">
  <button
    (click)="getActions($event)"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    matTooltipPosition="below"
    [matTooltip]="'actions' | intl"
  >
    <mat-icon color="primary">more_vertical</mat-icon>
  </button>
</div>

<ng-template #noActions>
  <div style="display: flex; justify-content: center">{{ 'no_actions' | intl }}</div>
</ng-template>
