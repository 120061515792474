import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '@rcg/auth';
import { FilterFavoriteSettings } from '@rcg/filters/models';
import { FiltersResult } from '@rcg/filters/models/filters-result';
import { FavoritesService } from '@rcg/filters/services';
import { tr } from '@rcg/intl';
import { MetricNumberPipe } from '@rcg/standalone/pipes/metric-number.pipe';
import { FilterSetting } from '../../models/filters-settings';
import { FiltersService } from '../../services/filters.service';
import { FiltersDialogComponent } from '../filters-dialog/filters-dialog.component';

@Component({
  selector: 'rcg-filters',
  standalone: true,
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
  imports: [CommonModule, MatIconModule, MatTooltipModule, FiltersDialogComponent, MatBadgeModule, MetricNumberPipe],
})
export class RcgFiltersComponent {
  @Input() filterSetting?: FilterSetting | undefined | null;

  @Input() favoriteSettings?: FilterFavoriteSettings | undefined;

  @Input() count?: number | undefined;

  @Output() filter = new EventEmitter<FiltersResult>();

  constructor(private filtersService: FiltersService, private favoritesService: FavoritesService, private auth: AuthService) {}

  activeFilter = toSignal(this.filtersService.activeFilter$);
  noActiveFilterTr = toSignal(tr('no_active_filters'));

  iconSettings = computed(() => {
    const filter = this.activeFilter();
    return filter?.whereExpressions?.length || filter?.orderByExpressions?.length
      ? { color: 'red', tooltip: `${filter.orderbyDescription ?? ''}  ${filter?.filtersDescription ?? ''}` }
      : { color: undefined, tooltip: this.noActiveFilterTr() ?? '' };
  });

  openFilters(filterConfig: FilterSetting, favoriteSettings: FilterFavoriteSettings | undefined): void {
    if (!filterConfig?.id) return;

    const favoriteId = favoriteSettings?.favorite?.id;
    const formModel = favoriteId
      ? this.filtersService.getFilterFormSetting(favoriteId)?.formModel ?? favoriteSettings?.favorite?.filters_model ?? {}
      : this.filtersService.getFilterFormSetting(filterConfig.id)?.formModel ?? {};

    this.filtersService.openFiltersForm({
      ...filterConfig,
      formModel: formModel,
      favoritesSettings: favoriteSettings,
      onSubmitAction: async (fResult) => {
        const favoriteResult = fResult.favoriteResult;
        const filterId = favoriteResult?.favoriteId ? favoriteResult?.favoriteId : fResult.filterFormSetting.id;
        this.filtersService.changeFilter(filterId, fResult);

        this.filter.emit({
          id: filterId,
          filters: fResult.filters,
        });

        if (favoriteResult?.action === 'update') {
          await this.favoritesService.updateFavorite({
            filters: fResult.filters,
            filtersModel: fResult.filterFormSetting.formModel,
            favoriteId: favoriteResult.favoriteId!,
            description: favoriteResult.description!,
            users: favoriteResult.users,
            groups: favoriteResult.groups,
          });
          return;
        }

        if (favoriteResult?.action === 'insert') {
          await this.favoritesService.saveFavorite({
            filters: fResult.filters,
            filtersModel: fResult.filterFormSetting.formModel,
            filtersSettingsId: fResult.filterFormSetting.id!,
            moduleId: favoriteResult.moduleId!,
            description: favoriteResult.description!,
            category: favoriteResult.category!,
            routePath: favoriteResult.routePath!,
            users: favoriteResult.users,
            groups: favoriteResult.groups,
          });
          return;
        }
      },
      onResetAction: async (data) => {
        const { id, favorite } = data;
        if (!id) return;
        const user = this.auth.user();
        if (favorite?.id && user?.id !== favorite?.user_id) {
          // assigned favorite
          this.filtersService.clearToInitalFavoriteFilter(id, favorite);
          this.filter.emit({ filters: favorite.filters, id });
          return;
        }
        this.filtersService.clearSelectedFilter(id);
        this.filter.emit({ filters: undefined, id });
      },
    });
  }
}
