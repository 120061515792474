import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IAction } from '../../models';
import { MultiFabActionsComponent } from './multi-fab-actions/multi-fab-actions.component';
import { SingleFabActionComponent } from './single-fab-action/single-fab-action.component';

@Component({
  selector: 'rcg-fab-actions',
  standalone: true,
  imports: [CommonModule, SingleFabActionComponent, MultiFabActionsComponent],
  templateUrl: './fab-actions.component.html',
  styleUrls: ['./fab-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FabActionsComponent {
  @Input() actions: IAction[] = [];

  @Input() expandableButtonIcon = 'add';

  @Input() alwaysExpanded = false;

  @Input() collapseOnClick = true;

  @Output() actionClicked = new EventEmitter<IAction>();

  onActionClick(action: IAction) {
    this.actionClicked.emit(action);
  }
}
