import { WhereExpression } from '@npm-libs/ng-templater';
import { SqlWhereExpression } from '@rcg/filters/models';
import { DateFilterOperator } from './date-filter';
import { DateExpression } from './expressions/base';
import { DateBetweenExpression } from './expressions/date-between';
import { DateEqExpression } from './expressions/date-eq';
import { DateGtExpression } from './expressions/date-gt';
import { DateGteExpression } from './expressions/date-gte';
import { DateLtExpression } from './expressions/date-lt';
import { DateLteExpression } from './expressions/date-lte';
import { MonthExpression } from './expressions/month';
import { PreviousMonthExpression } from './expressions/previous-month';
import { PreviousWeekExpression } from './expressions/previous-week';
import { TodayExpression } from './expressions/today';
import { WeekExpression } from './expressions/week';
import { YearExpression } from './expressions/year';
import { YesterdayExpression } from './expressions/yesterday';

export class DateFilterExpression {
  static createGqlExpression(
    field: string,
    operator: DateFilterOperator,
    date: Date | undefined,
    toDate: Date | undefined,
  ): WhereExpression | undefined {
    return this.getOperatorExpression(field, operator, date, toDate).createGqlExpression();
  }

  static createSqlExpression(
    field: string,
    operator: DateFilterOperator,
    date: Date | undefined,
    toDate: Date | undefined,
  ): SqlWhereExpression | undefined {
    return this.getOperatorExpression(field, operator, date, toDate).createSqlExpression();
  }

  private static getOperatorExpression(
    field: string,
    operator: DateFilterOperator,
    date: Date | undefined,
    toDate: Date | undefined,
  ): DateExpression {
    switch (operator) {
      case 'day':
        return new TodayExpression(field, operator, date);
      case 'yesterday':
        return new YesterdayExpression(field, operator, date);
      case 'week':
        return new WeekExpression(field, operator, date);
      case 'previousWeek':
        return new PreviousWeekExpression(field, operator, date);
      case 'month':
        return new MonthExpression(field, operator, date);
      case 'previousMonth':
        return new PreviousMonthExpression(field, operator, date);
      case 'year':
        return new YearExpression(field, operator, date);
      case 'eq':
        return new DateEqExpression(field, operator, date);
      case 'gt':
        return new DateGtExpression(field, operator, date);
      case 'gte':
        return new DateGteExpression(field, operator, date);
      case 'lt':
        return new DateLtExpression(field, operator, date);
      case 'lte':
        return new DateLteExpression(field, operator, date);
      case 'between':
        return new DateBetweenExpression(field, operator, date, toDate);
      default:
        throw new Error(`Unsupported date expression: ${operator}`);
    }
  }
}
