import { Injectable, inject } from '@angular/core';
import { AuthService } from '@rcg/auth';
import { FilterExpressions } from '@rcg/core/models/filter-expressions';
import { GraphqlClientService } from '@rcg/graphql';
import { MessageService } from '@rcg/standalone/services';
import { catchError, concat, filter, firstValueFrom, map, of, switchMap, take } from 'rxjs';
import {
  deleteFavorite,
  favoritesQuery,
  favoritesSubscription,
  getFavoritesGroups,
  getFavoritesUsers,
  insertFavorite,
  updateFavorite,
} from '../gql/favorites.gql';
import { Favorite, FavoritesGroup, FavoritesUser } from '../models/favorites/favorites';

@Injectable({ providedIn: 'root' })
export class FavoritesService {
  private readonly graphQlClient = inject(GraphqlClientService);
  private readonly messageService = inject(MessageService);
  private readonly auth = inject(AuthService);

  getFavoritesQueryFirstThenSubscription$(moduleId: number) {
    return concat(this.getFavorites$(moduleId, false).pipe(take(1)), this.getFavorites$(moduleId, true));
  }

  async deleteFavorite(id: number, favoriteUserId: number) {
    if (!id || !favoriteUserId) throw new Error(`No favorite id or owner id. Id:${id} - OwnerId:${favoriteUserId}`);
    try {
      const isOwner = (await firstValueFrom(this.auth.user$))?.id === favoriteUserId;

      if (!isOwner) {
        this.messageService.showWarningSnackbar(`No permissions to delete favorite. Only owner can delete favorite`);
        return;
      }
      const resultId = await firstValueFrom(
        this.graphQlClient.mutate<{ data?: { id: number | undefined } | undefined }>({
          mutation: deleteFavorite,
          variables: {
            id: id,
          },
        }),
      );
      if (!resultId?.data?.id) throw new Error('Not deleted - no result id');
      this.messageService.showInfoSnackbar(`Favorite ${id} deleted ${id}:`);
    } catch (error) {
      this.messageService.showErrorSnackbar(`Error delete favorite ${id}: ${error}`);
    }
  }

  async saveFavorite(input: {
    moduleId: number;
    filters: FilterExpressions;
    filtersModel: Record<string, unknown> | null;
    description: string;
    filtersSettingsId: number;
    category: string;
    routePath: string;
    users?: number[];
    groups?: number[];
  }): Promise<void> {
    try {
      if (!input?.moduleId) throw new Error('No module id');
      if (!input?.filtersSettingsId) throw new Error('No filters settings id');
      if (!input.filters) throw new Error('No filters');
      if (!input.description) throw new Error('No filters description');

      const user = this.auth.user();
      const tenant = this.auth.tenant();
      if (!user?.id) throw new Error('No user data');
      if (!tenant?.id) throw new Error('No tenant data');

      await firstValueFrom(
        this.graphQlClient.mutate<{ data?: Record<string, unknown> | undefined }>({
          mutation: insertFavorite,
          variables: {
            tenantId: tenant.id,
            userId: user.id,
            filters_settings_id: input.filtersSettingsId,
            moduleId: input.moduleId,
            description: input.description,
            filters: input.filters,
            filtersModel: input.filtersModel ?? {},
            category: input.category,
            routePath: input.routePath,
            users: input.users ?? [],
            groups: input.groups ?? [],
          },
        }),
      );

      this.messageService.showInfoSnackbar('Favorite saved');
    } catch (error) {
      this.messageService.showErrorSnackbar(`Error save favorite: ${error}`);
    }
  }

  async updateFavorite(input: {
    favoriteId: number;
    filters: FilterExpressions;
    filtersModel: Record<string, unknown> | null;
    description: string;
    users?: number[];
    groups?: number[];
  }): Promise<boolean> {
    try {
      if (!input.favoriteId) throw new Error('No Favorite id');
      if (!input.description) throw new Error('No Favorite description');

      const user = this.auth.user();
      if (!user?.id) throw new Error('No user data');

      await firstValueFrom(
        this.graphQlClient.mutate<{ data?: Record<string, unknown> | undefined }>({
          mutation: updateFavorite,
          variables: {
            id: input.favoriteId,
            filters: input.filters,
            filtersModel: input.filtersModel ?? {},
            description: input.description,
            users: input.users ?? [],
            groups: input.groups ?? [],
          },
        }),
      );

      this.messageService.showInfoSnackbar('Favorite updated');
      return true;
    } catch (error) {
      this.messageService.showErrorSnackbar(`Error update favorite to databse: ${error}`);
      return false;
    }
  }

  async getFavoritesUsers(usersIds: number[]): Promise<FavoritesUser[]> {
    if (!usersIds.length) {
      return [];
    }
    const users = await firstValueFrom(
      this.graphQlClient.query<{ data: FavoritesUser[] }>({
        variables: { ids: usersIds },
        query: getFavoritesUsers,
      }),
    );
    return users.data ?? [];
  }

  async getFavoritesTenantGroups(tenantId: number, groupsIds: number[]): Promise<FavoritesGroup[]> {
    if (!tenantId) throw new Error('No tenant id');

    if (!groupsIds?.length) {
      return [];
    }
    const groups = await firstValueFrom(
      this.graphQlClient.query<{ data: FavoritesGroup[] }>({
        variables: {
          tenant_id: tenantId,
          ids: groupsIds,
        },
        query: getFavoritesGroups,
      }),
    );
    return groups?.data ?? [];
  }

  private getFavorites$(moduleId: number, subscription = false) {
    return this.auth.authInfo$.pipe(
      filter(({ user, tenant }) => !!user?.id && !!tenant?.id && !!moduleId),
      switchMap((s) => {
        const groups = `{${(s.tenant?.groups ?? []).map((g) => g.id).join(',')}}`;
        const vars = {
          tenantId: s.tenant?.id,
          userId: s.user!.id,
          moduleId: moduleId,
          tenantGroups: groups,
        };
        return (
          subscription
            ? this.graphQlClient.subscribe<{ data?: Favorite[] | undefined }>({
                query: favoritesSubscription,
                variables: vars,
              })
            : this.graphQlClient.query<{ data?: Favorite[] | undefined }>({
                query: favoritesQuery,
                variables: vars,
              })
        ).pipe(
          map((response) => response.data ?? []),
          catchError((e) => {
            console.error(`Error get Favorites for module: ${moduleId}`, e);
            return of([]);
          }),
        );
      }),
    );
  }
}
