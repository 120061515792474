/* eslint-disable @nx/enforce-module-boundaries */
import { Component, Input } from '@angular/core';
import { ContactCardData } from '@rcg/core/models';
import { PhoneService } from '@rcg/phone/services/phone.service';

@Component({
  selector: 'rcg-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
})
export class ContactCardComponent {
  open = window.open;

  @Input() data?: ContactCardData;

  constructor(public phoneService: PhoneService) {}

  teams(email: string | null | undefined) {
    if (!email) return;
    window.open(`https://teams.microsoft.com/l/chat/0/0?users=${email}`, '_blank');
  }
}
