import { FormlyFieldConfig } from '@ngx-formly/core';
import { OrderByExpression } from '@npm-libs/ng-templater';
import { OrderByConfig } from '@rcg/filters/models/order-by-config';
import { SortField, SortFieldSettings } from '@rcg/forms/fields/sort-field/sort-field.component';
import { tr } from '@rcg/intl';
import { IOrderBy } from './base';

export class OrderByFilter extends IOrderBy<OrderByConfig> {
  private readonly fieldKey = 'sort';

  override createOrderByExpression(data: Record<string, unknown>): OrderByExpression[] {
    const value = this.getValue(data, this.fieldKey);
    return value && value.length > 0
      ? value.map((s) => ({
          field: s.field,
          direction: s.direction!,
        }))
      : [];
  }

  override createFields(): FormlyFieldConfig[] {
    return [
      {
        key: this.fieldKey,
        type: 'sort',
        props: {
          settings: {
            fields: this.config.fields,
            sortByLabel: this.config.sortByLabel,
          } as SortFieldSettings,
        },
        expressions: {
          'props.label': tr(this.config.title) ?? tr('sort'),
          'props.placeholder': tr(this.config.placeholder ?? '') ?? tr('select_fields_to_sort'),
        },
      },
    ];
  }

  override getDescription(data: Record<string, unknown>): string {
    const value = this.getValue(data, this.fieldKey);
    const arrowUp = '\u2191';
    const arrowDown = '\u2193';

    return value && value.length > 0
      ? `${value.map((s) => `${s.label} ${s.direction === 'ascending' ? `${arrowUp}` : `${arrowDown}`}`)?.join(', ') ?? ''}\n`
      : '';
  }

  private getValue(data: Record<string, unknown>, propertyName: string): SortField[] | null {
    if (Object.prototype.hasOwnProperty.call(data, propertyName)) {
      return data[propertyName] as SortField[] | null;
    } else {
      return null;
    }
  }
}
