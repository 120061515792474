<ng-container *ngIf="(actions && actions.length > 0) || (actionsCollapsed && actionsCollapsed.length > 0)">
  <div #container class="container" [ngStyle]="containerStyles">
    <ng-container *ngFor="let action of actions">
      <button
        *ngIf="actionViewType === 'button-icon'"
        [color]="action | actionColor"
        (click)="onActionClicked($event, action)"
        mat-icon-button
        matTooltipPosition="below"
        [disabled]="action?.enabled === false"
        [matTooltip]="action | actionTooltip | intl : { default: action.tooltip ?? '' }"
      >
        <mat-icon [ngStyle]="actionStyles" [style.color]="action.color">{{ action | actionIcon }}</mat-icon>
      </button>

      <mat-icon
        *ngIf="actionViewType === 'icon'"
        style="margin: 0; padding: 0; cursor: pointer"
        [color]="action | actionColor"
        (click)="onActionClicked($event, action)"
        matTooltipPosition="below"
        [matTooltip]="action | actionTooltip | intl : { default: action.tooltip ?? '' }"
        [ngStyle]="actionStyles"
        >{{ action | actionIcon }}
      </mat-icon>
    </ng-container>

    <div *ngIf="collapsed">
      <button mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu>
        <ng-container *ngFor="let action of actionsCollapsed">
          <button mat-menu-item [disabled]="action?.enabled === false" (click)="onActionClicked($event, action); menuTrigger.closeMenu()">
            <mat-icon [ngStyle]="actionStyles" [style.color]="action.color">{{ action | actionIcon }}</mat-icon>
            <span [ngStyle]="{ color: action | actionColor, paddingLeft: '4px' }">
              {{ action | actionTooltip | intl : { default: action.tooltip ?? '' } }}</span
            >
          </button>
        </ng-container>
      </mat-menu>
    </div>
  </div>
</ng-container>
