<ng-container
  *ngIf="!actions || actions.length === 0; else alwaysExpanded ? notAlwaysExpandedTemplate : notAlwaysExpandedTemplate"
></ng-container>

<ng-template #alwaysExpandedTemplate>
  <div class="fab-container">
    <button color="primary" mat-fab>
      <i class="material-icons">{{ expandableButtonIcon }}</i>
    </button>
    <div>
      <button
        *ngFor="let action of actionButtons; let i = index"
        mat-mini-fab
        class="fab-primary"
        color="primary"
        (click)="onActionClick(action)"
        [matTooltip]="(actions[i].tooltip | intl) ?? ''"
        matTooltipPosition="left"
      >
        <mat-icon>{{ action.icon ?? '' }}</mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #notAlwaysExpandedTemplate>
  <div id="fab-dismiss" *ngIf="fabTogglerState === 'active'" (click)="onFabToggle()"></div>
  <div class="fab-container">
    <button color="primary" mat-fab class="fab-toggler" (click)="onFabToggle()">
      <i class="material-icons" [@fabToggler]="{ value: fabTogglerState }">{{ expandableButtonIcon }}</i>
    </button>
    <div [@speedDialStagger]="actionButtons.length">
      <button
        *ngFor="let action of actionButtons; let i = index"
        mat-mini-fab
        class="fab-primary"
        color="primary"
        (click)="onActionClick(action)"
        [matTooltip]="(actions[i].tooltip | intl) ?? ''"
        matTooltipPosition="left"
      >
        <mat-icon>{{ action.icon ?? '' }}</mat-icon>
      </button>
    </div>
  </div>
</ng-template>
