import { Injectable, inject } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { OrderByExpression, WhereExpression } from '@npm-libs/ng-templater';
import { AuthService } from '@rcg/auth';
import { GraphqlClientService } from '@rcg/graphql';
import { tr } from '@rcg/intl';
import { firstValueFrom } from 'rxjs';
import { createFilter } from '../filters';
import { Favorite, FilterConfig, FilterFavoriteSettings, IFilter, IWhereFilter, SqlFilterExpressions, SqlWhereExpression } from '../models';
import { FiltersDataContext } from '../models/filter-data-context';
import { OrderByConfig } from '../models/order-by-config';
import { OrderByFilter } from '../order-by/order-by';
import { FavoritesService } from './favorites.service';

@Injectable({ providedIn: 'root' })
export class FiltersBuilderService {
  authService = inject(AuthService);
  graphQlClient = inject(GraphqlClientService);
  favoritesService = inject(FavoritesService);

  async createFilters(
    filterConfig: FilterConfig[],
    orderByConfig?: OrderByConfig,
  ): Promise<{
    filters: IFilter[];
    orderBy?: OrderByFilter;
  }> {
    if (filterConfig.length === 0 && !orderByConfig) throw new Error('Filters configs are empty');
    const dataContext = await this.getDataContext();
    const filters: IFilter[] = [];
    for (const f of filterConfig) {
      filters.push(createFilter(f, dataContext));
    }

    let orderBy: OrderByFilter | undefined = undefined;
    if (orderByConfig) {
      orderBy = new OrderByFilter(orderByConfig);
    }

    if (filters.length === 0 && !orderBy) throw new Error('Filters are empty');
    return { filters, orderBy };
  }

  async createFormFields(
    filters: IFilter[],
    orderBy?: OrderByFilter,
    favoritesSettings?: FilterFavoriteSettings | undefined,
  ): Promise<FormlyFieldConfig[]> {
    let fields: FormlyFieldConfig[] = [];

    if (orderBy) {
      fields = [...orderBy.createFields()];
    }

    for (const f of filters) {
      fields = [...fields, ...f.createFields()];
    }
    const moduleId = favoritesSettings?.moduleId;
    const saveFavorites = favoritesSettings?.saveFavorites ?? false;
    const favorite = favoritesSettings?.favorite;

    const saveToFavorites = !!moduleId && saveFavorites && !favorite?.id;
    const saveFields = await this.saveFavoritesFileds(favorite);
    if (saveToFavorites) {
      fields = [...saveFields, ...fields];
    }

    if (favorite?.id) {
      const editFields = await this.editFavoritesFileds(favorite);
      fields = [...editFields, ...fields];
    }
    return fields;
  }

  async createGqlFilterExpressions(filters: IFilter[], model: Record<string, unknown>) {
    let whereExpressions: WhereExpression[] = [];
    let filtersTitle = '';

    for (const filter of filters) {
      const desc = await filter.getFilterDescription(model);
      const title = desc?.trim() ?? '';
      if (filter instanceof IWhereFilter) {
        const expression = filter.createGqlFilterExpression(model);
        whereExpressions = Array.isArray(expression) ? [...whereExpressions, ...expression] : [...whereExpressions, expression];
        filtersTitle += title ? `${title}, ` : '';
      }
    }
    filtersTitle = this.removeSemicolon(filtersTitle);

    const descTitlePrefix = await firstValueFrom(tr('filters'));

    return {
      whereExpressions: whereExpressions,
      title: `${filtersTitle ? `${descTitlePrefix}: ${filtersTitle}` : ''}`,
    };
  }

  async createSqlFilterExpressions(filters: IFilter[], model: Record<string, unknown>): Promise<SqlFilterExpressions> {
    let whereExpressions: SqlWhereExpression[] = [];
    let filtersTitle = '';

    for (const filter of filters) {
      if (filter instanceof IWhereFilter) {
        whereExpressions = [...whereExpressions, ...filter.createSqlFilterExpression(model)];
      }

      const desc = await filter.getFilterDescription(model);
      const title = desc?.trim() ?? '';
      filtersTitle += title ? `${title}, ` : '';
    }

    return {
      whereExpressions: Object.fromEntries(whereExpressions.map((sqlExpression) => [sqlExpression.field, sqlExpression])),
      title: filtersTitle,
    };
  }

  createOrderByExpression(orderBy: OrderByFilter | undefined, model: Record<string, unknown>) {
    let orderByExpressions: OrderByExpression[] = [];
    let title = '';

    if (orderBy) {
      orderByExpressions = orderBy.createOrderByExpression(model);
      title = orderBy.getDescription(model) ?? '';
    }

    title = this.removeSemicolon(title);

    return {
      orderByExpressions: orderByExpressions,
      tilte: title ? `Sort: ${title}` : '',
    };
  }

  private async getDataContext(): Promise<FiltersDataContext> {
    const user = this.authService.user()!;
    const tenant = this.authService.tenant()!;
    const dataContext = {
      user,
      userId: user.id,
      tenantId: tenant!.id,
      orgId: tenant!.organization!.id,
      organizations_share_type: tenant!.organizationShareType,
      tenant: tenant,
    };

    if (!dataContext || Object.keys(dataContext).length === 0) throw new Error('Filters Data context is empty');
    if (!dataContext.orgId) throw new Error('Filters Data context no organizationId');
    if (!dataContext.userId) throw new Error('Filters Data context nouserId');
    if (!dataContext.tenantId) throw new Error('Filters Data context no tenantId');
    return dataContext;
  }

  private removeSemicolon(str: string) {
    if (!str?.trim()) return str;
    return str.trim().endsWith(',') && !str.slice(str.lastIndexOf(',') + 1).trim().length ? str.slice(0, str.lastIndexOf(',')).trim() : str;
  }

  private async saveFavoritesFileds(favorite?: Favorite): Promise<FormlyFieldConfig[]> {
    return [
      {
        fieldGroupClassName: 'd-lg-flex align-items-lg-stretch',
        fieldGroup: [
          {
            className: 'col-lg-4 pe-lg-3',
            key: 'favorites_save',
            type: 'checkbox',
            defaultValue: false,
            expressions: {
              'props.label': tr('save_to_favorites'),
              'props.placeholder': tr('save_to_favorites'),
            },
          },
          {
            className: 'col-lg-8',
            key: 'favorites_description',
            type: 'input',
            props: {
              type: 'text',
            },
            expressions: {
              'props.label': tr('favorite_description'),
              'props.placeholder': tr('favorite_description'),
            },
            expressionProperties: {
              'props.required': (model) => !!model?.favorites_save === true,
            },
          },
        ],
      },
      ...(await this.getUsersAndGroupsFields(favorite)),
    ];
  }

  private async editFavoritesFileds(favorite: Favorite): Promise<FormlyFieldConfig[]> {
    const user = this.authService.user();
    const isOwner = favorite.user_id === user?.id;

    return [
      {
        fieldGroupClassName: 'd-lg-flex align-items-lg-stretch',
        fieldGroup: [
          {
            className: 'col-lg-9  pe-lg-3',
            key: 'favorites_description_edit',
            defaultValue: favorite.description ?? '',
            type: 'input',
            props: {
              readonly: !isOwner,
              required: true,
              type: 'text',
            },
            expressions: {
              'props.label': tr('favorite_description'),
              'props.placeholder': tr('favorite_description'),
            },
          },
          {
            className: 'col-lg-3',
            key: 'favorite_owner',
            defaultValue: favorite?.user?.full_name ?? '',
            type: 'input',
            props: {
              readonly: true,
              type: 'text',
            },
            expressions: {
              'props.label': tr('favorite_owner'),
              'props.placeholder': tr('favorite_owner'),
            },
          },
        ],
      },
      ...(await this.getUsersAndGroupsFields(favorite)),
    ];
  }

  private async getUsersAndGroupsFields(favorite?: Favorite): Promise<FormlyFieldConfig[]> {
    const user = this.authService.user();
    const tenant = this.authService.tenant();

    const isAdmin = tenant!.isAdmin ?? false;
    const isFavoriteOwner = !!favorite?.user_id && favorite?.user_id === user?.id;
    const canEditFavorites = isAdmin && (isFavoriteOwner || !favorite?.id); // !favorite?.id - when admin create new favorite

    if (!canEditFavorites) return [];

    return [
      {
        key: 'user_tenant_id',
        hide: !isAdmin,
        defaultValue: tenant!.id,
      },
      {
        key: 'favorite_users',
        type: 'autocompletemultiselect',
        hide: !isAdmin,
        expressions: {
          'props.label': tr('favorites_users'),
          'props.placeholder': tr('favorites_users'),
        },
        props: {
          settings: {
            preventOpenDetailDialog: true,
            chipsTooltip: (selectValue: Record<string, unknown> | undefined) =>
              ((selectValue?.['data'] as Record<string, unknown> | undefined)?.['username'] as string | undefined) ?? '',
            field: 'favorite_users',
            searchValue: {
              value: 'id',
              label: 'full_name',
            },
            additionalDataFields: ['username'],
            gqlVariablesFromModel: {
              tenant_id: 'user_tenant_id',
            },
            query: `
              query user($search: String, $tenant_id: bigint!) {
                data: identity_search_users(
                  args: { search: $search }
                  limit: 10
                  where: {
                    _and: [
                      { disabled: { _eq: false } }
                      {
                        tenant_group_members: {
                          _and: [
                            { tenant_id: { _eq: $tenant_id } }
                            {
                              tenant_group: {
                                _and: [{ enabled: { _eq: true } }, { _or: [{ is_admin: { _eq: true } }, { is_agent: { _eq: true } }] }]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                  order_by: [{ last_name: asc }, { first_name: asc }]
                ) {
                  id
                  full_name
                  username
                }
              }
            `,
            result: 'data',
            search: {
              prefix: '',
              suffix: '',
              variable: 'search',
            },
          },
        },
      },
      {
        key: 'favorite_groups',
        type: 'autocompletemultiselect',
        hide: !isAdmin,
        expressions: {
          'props.label': tr('favorites_groups'),
          'props.placeholder': tr('favorites_groups'),
        },
        props: {
          settings: {
            field: 'favorite_groups',
            searchValue: {
              value: 'id',
              label: 'description',
            },
            query: `
              query identity_search_tenant_groups($search: String, $tenant_id: bigint!) {
                data: identity_search_tenant_groups(
                  args: { search: $search }
                  limit: 10
                  where: { _and: [{ tenant_id: { _eq: $tenant_id } }] }
                ) {
                  id
                  description
                }
              }
            `,
            gqlVariablesFromModel: {
              tenant_id: 'user_tenant_id',
            },
            result: 'data',
            search: {
              prefix: '',
              suffix: '',
              variable: 'search',
            },
          },
        },
      },
    ];
  }
}
