import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IntlModule } from '@rcg/intl';
import { ActionResult, IAction, ToggleDataButtonActionConfig } from '../../models';
import { ActionColorPipe, ActionIconPipe, ActionTooltipPipe } from '../../pipes/actions.pipe';
import { ActionsService } from '../../services/actions.service';

type ActionRowPosition = 'start' | 'center' | 'end';

type actionViewType = 'icon' | 'button-icon';

@Component({
  selector: 'rcg-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    ActionColorPipe,
    ActionTooltipPipe,
    ActionIconPipe,
    MatMenuModule,
    IntlModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsComponent implements OnInit, OnChanges {
  @Output() actionClicked = new EventEmitter<IAction>();

  @Output() actionResult = new EventEmitter<ActionResult>();

  @Input() actions: IAction[] = [];
  ngOnChanges() {
    this.onResizeHandler(true);
  }

  @Input() directDispatchAction = false;

  @Input() rowPosition: ActionRowPosition | null = null;

  @Input() actionSize = '24px';

  @Input() actionViewType: actionViewType = 'button-icon';

  @Input() collapsable: boolean = false;

  @ViewChild('container') containerInside?: ElementRef<HTMLElement>;
  container?: HTMLElement;

  containerStyles = {};
  actionStyles = {};

  activeToggleButtons: { [index: number]: boolean } = {};

  collapsed: boolean = false;

  actionsCollapsed: IAction[] = [];

  constructor(
    private actionsService: ActionsService,
    private changeRef: ChangeDetectorRef,
    private readonly containerOutside: ElementRef,
  ) {}

  ngOnInit(): void {
    if (this.rowPosition) {
      this.containerStyles = {
        'justify-content': this.rowPosition,
      };
    }

    this.actionStyles = {
      margin: 0,
      padding: 0,
      'font-size': this.actionSize,
    };
  }

  @HostListener('window:resize')
  onResizeHandler(firstCall: boolean = false) {
    if (this.collapsable) {
      this.container = this.containerInside?.nativeElement;
      if (this.container == undefined && (this.containerOutside.nativeElement.clientWidth ?? 0) > 20) {
        this.container = this.containerOutside.nativeElement;
      }

      if (this.container) {
        let spots: number = Math.floor((this.container.clientWidth - 20) / 48);
        if (this.collapsed || (firstCall && spots < this.actions.length)) {
          spots--;
        }
        if (this.collapsed && spots + 1 == this.actions.length + this.actionsCollapsed.length) {
          while (this.actionsCollapsed.length > 0) {
            const action = this.actionsCollapsed[0];
            this.actionsCollapsed.splice(0, 1);
            this.actions.push(action);
          }
          this.collapsed = false;
        } else if (spots > this.actions.length) {
          while (this.actionsCollapsed.length > 0 && this.actions.length < spots) {
            const action = this.actionsCollapsed[0];
            this.actionsCollapsed.splice(0, 1);
            this.actions.push(action);
          }
          this.collapsed = this.actionsCollapsed.length > 0;
        } else if (spots < this.actions.length) {
          while (this.actions.length > 0 && this.actions.length > spots) {
            const action = this.actions.pop()!;
            this.actionsCollapsed = [action, ...this.actionsCollapsed];
          }
          this.collapsed = this.actionsCollapsed.length > 0;
        }
      }
    }
  }

  async onActionClicked(event: MouseEvent, action: IAction) {
    event?.stopPropagation();

    if (action.method === 'toggle-data-button' && action.id) {
      const config = action.config as ToggleDataButtonActionConfig;
      if (config) {
        const active = !(action.id in this.activeToggleButtons ? this.activeToggleButtons[action.id] : false);
        this.activeToggleButtons[action.id] = active; // set new active
        const newConfig = { ...config, active: active, executeGql: active ? config.gqlInputActive : config.gqlInputNotActive };
        const newAction = { ...action, config: newConfig };
        this.actions = this.actions.map((a) => (a.id === action.id ? newAction : a));
        await this.emitAction(newAction);
        this.changeRef.markForCheck();
        return;
      }
    }
    await this.emitAction(action);
  }

  private async emitAction(action: IAction) {
    if (this.directDispatchAction) {
      await this.actionsService.executeAction(action, (result) => {
        if (result) {
          this.actionResult.emit(result);
        }
      });
      return;
    }
    this.actionClicked.emit(action);
  }
}
