import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '@rcg/auth';
import { IntlModule } from '@rcg/intl';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { IAction } from '../../models/actions';
import { ActionsService } from '../../services/actions.service';

@Component({
  selector: 'rcg-form-field-actions',
  standalone: true,
  templateUrl: './form-field-actions.component.html',
  styleUrls: ['./form-field-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatMenuModule, MatButtonModule, MatIconModule, MatTooltipModule, IntlModule],
})
export class FormFieldActionsComponent {
  @Input() formId!: number;

  @Input() recordId!: number;

  @Input() fieldId!: string;

  actionsError$ = new BehaviorSubject<string | null>(null);

  actions$ = new BehaviorSubject<IAction[]>([]);

  constructor(private actionsService: ActionsService, private authService: AuthService) {}

  async getActions(event: MouseEvent): Promise<void> {
    try {
      event?.stopPropagation();
      event?.preventDefault();

      this.actionsError$.next(null);

      if (!this.formId || !this.fieldId || !this.recordId) {
        const error = !this.formId ? 'FormId' : !this.recordId ? 'RecordId' : 'fieldId';
        this.actionsError$.next(`Missing required form field actions input:  ${error}`);
        return;
      }

      const tenant = this.authService.tenant()!;

      const actions = await firstValueFrom(
        this.actionsService.getFormFieldActions({
          contextType: 'form-field',
          formId: this.formId!,
          fieldId: this.fieldId!,
          recordId: this.recordId!,
          organizationId: tenant!.organization.id,
          tenantId: tenant.id,
        }),
      );

      this.actions$.next(actions);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Form field actions error', error);
      this.actions$.next([]);
      this.actionsError$.next(error?.message ?? error?.toString());
    }
  }

  async executeAction(_: MouseEvent, action: IAction): Promise<void> {
    await this.actionsService.executeAction(action);
  }
}
